* {
    margin: 0;
    padding: 0;
    font-family: 'Nunito Sans', sans-serif !important;
  }


  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #e6f7ff inset;
    background-color: white !important;
  }
  
  input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0px 1000px #e6f7ff inset;
    background-color: white !important; 
  }